.circularProgress{
    height: 30px !important;
    border: 0px;
}
.circularProgress > svg{
    padding: 1px !important;
}
.react_select > div > div  {
    padding: 0px !important;
    /* margin-left:2px; */
    /* border: 2px solid red !important; */
}
.react_select > div > div > div {
    padding: 3px !important;
    /* z-index: 400 !important; */
    /* border: 2px solid red !important; */
}
::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #025056;
    border-radius: 20px;
    border: 6px solid #00000026;
    background-clip: content-box;
}