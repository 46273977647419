.workOrder {
  .table .editable-table th,
  .table .editable-table td {
    min-width: 45px;
  }

  .table th input {
    color: #fff;
  }

  .Overlay {
    background-color: rgba(0, 0, 0, 0.55);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    display: none;
    z-index: 5;
  }

  .Overlay.Show {
    display: block;
  }

  .Modal {
    position: fixed;
    background-color: #fff;
    width: 40%;
    box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
    right: -350vh;
    padding: 0 12px 12px;
    transition: all 0.7s ease-out;
    z-index: 999;
    overflow: auto;
    top: 50px;
  }

  .Modal .card-header h4 {
    color: #000;
  }

  .Modal.Show {
    right: 0;
    height: 80%;
  }

  .card-footer .btn {
    margin-bottom: 0;
  }

  .material-table__toolbar-wrap {
    position: absolute;
    top: 0;
    right: 0;
  }

  .stickyFooter {
    position: sticky;
    bottom: 0;
    background-color: #fff;
  }

  .addtional-col {
    text-align: center;
    background: #ccc;
  }
}
