.contaniner{
    font-size: medium;
    display: flex;
    flex-direction: column;
    font-weight: 500;
}
.header{    
    font-size: large;
    display: flex;
    flex-direction: row;
    padding: 5px 10px;
    font-weight: 600;
    justify-content: space-between;
    text-transform: capitalize;
}
.headerTitle{
    min-width: auto;
}
.headerButton{
    min-width: 80px;
    text-align: end;
}
.headerButton > *{
    padding: 3px;
    width: 25px;
    height: 20px;
}
.content{    
    font-size: medium;
    display: flex;
    flex-direction: row;
    padding: 5px 10px;
    font-weight: 500;
    justify-content: space-between;
    text-transform: capitalize;
}
.contentTitle{
    color:rgb(109, 109, 109);
    display: flex;
    flex-direction: row;
}
.contentTitle > span{
    color:rgb(109, 109, 109);
    min-width:60px
}
