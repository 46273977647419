.main_header {
    width: 100%;
    background: rgb(14, 14, 136);
    color: rgb(36, 31, 31);
    display: flex;
    font-family: 'Josefin Sans' !important;
}

.container {
    position: fixed;
    top: 0px;
    z-index: 1000;
    width: 100%;
    background: #025056;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    font-family: 'Josefin Sans' !important;
}

.logo_container {
    display: flex;
    align-items: center;
    width: 14%;
    height: 70px;
    justify-self: center;
    align-self: center;
    margin-left: 7rem;
}

.nev_toggle {
    height: unset;
    opacity: 0;
    justify-content: center;
    transition: all .4s ease .1s;
}

.top_navigation {
    padding: 8px;
    width: 70%;
}

.nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.nav>li {
    align-self: center;
    justify-self: center;
    padding: 1rem;
    color: #FFF;
    font-weight: 400;
    font-size: 1rem;
    min-width: 150px;
    list-style: none;
}

.nav>li>a {
    text-decoration: none;
    color: #fff;
    font-weight: 600;
}

.sub_menu {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 2;
    top: 76px;

}

.sub_menu_desktop_btn {
    background: #025056 !important;
    color: #fff;
}

.menu_item_4942 {
    transition: all .4s ease .1s;
}

.menu_item_4942>ul {
    opacity: 0;
    height: 0px;
}

.sub_menu>li {
    padding: 10px;
    line-height: 20px;
    letter-spacing: 2px;
    list-style: none;
}

.sub_menu>li>a {
    text-decoration: none;
    color: red
}

.sub_menu>li>a:hover {
    line-height: 20px;
    color: rgb(101, 85, 85);
}

.GetOuote {
    border: 2px solid red;
    background: red;
    padding: 2px;
    align-self: flex-end !important;
    margin-right: 20px !important;
    border-radius: 15px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    color: #fff;
    box-shadow: 2px 2px 1px #c31b1b, 3px 3px 2px 1px #0fc0cea8;
    transition: all .5s ease .2s;
}

.GetOuote:hover {
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

#mobile_nav_menu {
    display: none;
    width: 5%;
}
.showClass{
    animation: hinding 2s ease-in 0s 1 forwards;
}
@media screen and (min-width:1080px) and (max-width:1280px) {
    .nav {
        padding: .2rem;
    }

    .nav>li {
        min-width: 90px;
    }

    .logo_container {
        display: flex;
        align-items: center;
        width: 14%;
        margin-left: 8rem;
    }

    .sub_menu {
        top: 76px
    }
}

@media screen and (min-width:890px) and (max-width:1080px) {
    .nav {
        padding: .2rem;
    }

    .nav>li {
        min-width: 90px;
    }

    .logo_container {
        display: flex;
        align-items: center;
        width: 15%;
        margin-left: 4rem;
    }

    .sub_menu {
        top: 90px
    }
}

@media screen and (min-width:480px) and (max-width:890px) {
    .logo_container {
        display: flex;
        align-items: center;
        width: 18%;
        height: 10vh;
        margin-left: 4rem;
    }

    #mobile_nav_menu {
        display: block;
    }

    .nav {
        display: flex;
        flex-direction: column;
        position: absolute;
        /* background-color: #b61313; */
        min-width: 40%;
        /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
        z-index: 2;
        top: 10vh;
        right: 0px;
        padding: 2rem;
        padding-top: 0px;
        /* ---------glassmorphism--------- */
        background: #04828b9e;
        box-shadow: 0 8px 32px 0 rgba(31, 135, 119, 0.37);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 0px 0px 10px 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
        /* ---------glassmorphism--------- */
    }

    .sub_menu_desktop_btn {
        background: #02505600 !important;
        color: #fff;
    }

    .nav>li {
        align-self: baseline;
        justify-self: left;
        padding: 1rem;
        color: #FFF;
        font-size: 1rem;
        font-weight: 700;
    }

    .nav>li>a {
        text-decoration: none;
        color: #fff
    }

    .sub_menu {
        display: block;
        position: unset;
        background-color: unset;
        color: #fff;
        min-width: 160px;
        list-style: none;
        box-shadow: unset;
        z-index: 2;
        top: 150px;
        left: 20px
    }

    .sub_menu>li {
        line-height: 20px;
        letter-spacing: 2px;
    }

    .sub_menu>li>a {
        text-decoration: none;
        color: rgb(255, 255, 255)
    }

    .sub_menu>li>a:hover {
        line-height: 20px;
        color: rgb(101, 85, 85);
    }

    .GetOuote {
        align-items: center;
        border: 2px solid red;
        background: red;
        padding: 12px 10px;
        border-radius: 15px;
        color: #fff;
        box-shadow: 2px 2px 1px #c31b1b, 3px 3px 2px 1px #0fc0cea8;
        transition: all .5s ease .2s;
    }

    .GetOuote:hover {
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    }

    .mobile_nav_menu {
        display: block;
        width: 30%;

    }

}

@media screen and (max-width:480px) {
    .logo_container {
        display: flex;
        align-items: center;
        width: 40%;
        height: 8vh;
        margin-left: 4rem;
    }

    .nav {
        display: flex;
        flex-direction: column;
        position: absolute;
        /* background-color: #b61313; */
        min-width: 40%;
        /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
        z-index: 2;
        top: 8vh;
        right: 0px;
        padding: 2rem;
        padding-top: 0px;
        /* ---------glassmorphism--------- */
        background: #04828b9e;
        box-shadow: 0 8px 32px 0 rgba(31, 135, 119, 0.37);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 0px 0px 10px 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
        /* ---------glassmorphism--------- */
    }

    .sub_menu_desktop_btn {
        background: #02505600 !important;
        color: #fff;
    }

    .nav>li {
        align-self: baseline;
        justify-self: left;
        padding: 1rem;
        color: #FFF;
        font-size: 1rem;
        font-weight: 700;
        list-style: none;
    }

    .nav>li>a {
        text-decoration: none;
        color: #fff
    }

    .sub_menu {
        display: block;
        position: unset;
        background-color: unset;
        color: #fff;
        min-width: 160px;
        list-style: none;
        box-shadow: unset;
        z-index: 2;
        top: 150px;
        left: 20px;
    }

    .sub_menu>li {
        line-height: 20px;
        letter-spacing: 2px;
    }

    .sub_menu>li>a {
        text-decoration: none;
        color: rgb(255, 255, 255)
    }

    .sub_menu>li>a:hover {
        line-height: 20px;
        color: rgb(101, 85, 85);
    }

    .GetOuote {
        align-items: center;
        border: 2px solid red;
        background: red;
        padding: 2px;
        border-radius: 15px;
        /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
        color: #fff;
        box-shadow: 2px 2px 1px #c31b1b, 3px 3px 2px 1px #0fc0cea8;
    }


    #mobile_nav_menu {
        display: block;
        width: 30%;
    }

}
@keyframes hinding {
    0% {
        width: 200px;
        height: 300px;
    }

    25% {
        width: 100px;
        height: 200px;
    }

    50% {
        width: 50px;
        height: 100px;
    }

    100% {
        width: 0px;
        height: 0px;

    }
}