.DateAtt {
    border: 1px solid #d3d3d3;
    padding: 18px;
    border-radius: 5px;
    width: -webkit-fill-available;
}

.DateWrapperClass {
    margin-right: 0px;
}

.DateWrapperClass>div {
    width: -webkit-fill-available;
}

.DateWrapperClass>div>div:nth-child(2) {
    z-index: 3;
}

.DateWrapperClass>p {
    display: inline;
    position: relative;
    top: 10px;
    left: 12px;
    z-index: 2;
    background-color: #fff;
    padding: 3px;
}