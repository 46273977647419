.main_footer {
    width: 100%;
    min-height: 80vh;
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    background-color: black;
}

.contect_us>div>h1 {
    font-size: 36px;
}

.address>div>h1 {
    font-size: 36px;
}

.container {
    display: flex;
    flex-direction: row;
    padding: 3rem;
}

.container>div {
    width: 50%;
}

.main_1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.sitemap_content_main {
    font-size: x-large;
    font-weight: 700;
}

.sitemap_content>p>a {
    color: #fff;
    text-decoration: none;
}

.sitemap_content>p>a:hover {
    color: #1f848cca;
    text-decoration: none;
}

.main_2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.divider>div {
    border-bottom: 2px dashed #fff;
    margin: 20px 0px;

}

.Social_link {
    list-style: none;
}

.Social_link>li {
    display: inline-block;
    margin: 5px;
}