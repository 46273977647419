.ledger {

  .page-title{
    margin-bottom:0;
  }

  .react-table {
    input {
      color: #FFF;

    }
  }

  .modal-dialog {
    max-width: 850px;
  }

  .modal-body {
    font-size: 15px;

    .card {
      padding: 0;
    }
  }

  .modal-header,
  .modal-body {
    padding: 0.5rem 1rem;
    line-height: 1;

  }

  .modal-body .css-6j8wv5-Input {
    padding: 0;
    margin: 0;
  }

  .card-body {
    padding: 0;
  }

  .clearFilter {
    background: transparent;
    border: none;
    color: #025056;
  }



  .project-title>.css-26l3qy-menu {
    // position:relative;
    // position:relative;
    // top:0;
    // bottom:0;

    //  width: 100%;
    // margin-bottom: 15px;
    // overflow-y: visible;
    // -ms-overflow-style: -ms-autohiding-scrollbar;
    // border: 1px solid #ddd;
    // height: 120px;
  }

  .css-4ljt47-MenuList {
    color: #000;
  }

  .table .editable-table th,
  .table .editable-table td {
    padding: 10px;
  }

  .customModalDialog {
    max-width: 70vw;
  }
}