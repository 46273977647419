.imggrid {
    grid-template-columns: repeat(3, 1fr);
}

@media screen and (max-width: 1000px) {
    .imggrid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 780px) {
    .imggrid {
        grid-template-columns: repeat(1, 1fr);
    }
}