.gridbox {
    grid-template-columns: repeat(3, 1fr);
}


@media screen and (max-width: 1000px) {
    .gridbox {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 780px) {
    .gridbox {
        grid-template-columns: repeat(1, 1fr);
    }
}

.divcont {
    background: linear-gradient(180deg, #ccf9ff, #a5efff, #7ddaff, #1fcfff);
}


.imagecont {
    background: white;
}

.imagecont:hover .imagehover {
    transform: scale(1.3);

    transition: transform 1.5s;
}