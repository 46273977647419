.main {
    width: 100%;
    height: fit-content;
    margin: 0px;
    padding: 0px;
    padding-top: 10vh
}

.main_container {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 2rem 4rem 0rem 4rem;
    /* background: linear-gradient(aquamarine,#e1eeef); */

    height: auto;
}

.main_1 {
    width: 45%;
    padding: 12px 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.long_text {

    font-size: 2.9rem;
    font-weight: 700;
    font-stretch: ultra-expanded;
    letter-spacing: 2px;
    color: #025056;
}

.para {
    width: 80%;
    padding: 1rem 0rem;
    word-spacing: 2px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.Lets_Talk {
    font-weight: 600;
    color: rgb(244, 95, 20);
    font-weight: 600;
    font-size: large;
}

.Lets_Talk>a {
    text-decoration: none;
}

.Lets_Talk>a:hover {
    text-decoration: none;
    color: rgb(244, 95, 20);
    /* text-decoration: underline; */
}

.Lets_Talk:hover {
    color: rgb(244, 95, 20);
    text-decoration: underline;
}

.main_2 {

    width: 25%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-width: 251px;
}

.image {
    box-shadow: 2px 2px 6px #47836f;
    mix-blend-mode: multiply;
    transition: all .9s ease .1s;
    min-width: 100px
}

.image1:hover {
    mix-blend-mode: normal;
}

.image2:hover {
    mix-blend-mode: normal;
}

.image3:hover {
    mix-blend-mode: normal;
}

.main_3 {
    padding: 1rem;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-width: 321px;
}

@media screen and (min-width:840px) and (max-width:1080px) {
    .long_text {
        font-size: 2rem;
    }

    .main {
        padding-top: 8vh;
    }
}

@media screen and (min-width:480px) and (max-width:840px) {
    .main {
        padding-top: 8vh;
    }

    .main_container {
        flex-direction: column;
        padding: .5rem;
    }

    .main_1 {
        width: 100%;
        padding: 0px 5rem;
        align-items: center;
    }

    .long_text {
        font-size: 2rem;
        text-align: center;
    }

    .para {
        align-self: center
    }

    .Lets_Talk {
        text-align: center;
    }

    .main_2 {
        display: none;
    }

    .main_3 {
        display: none;
    }
}

@media screen and (max-width:480px) {
    .main {
        padding-top: 8vh;
    }

    .main_container {
        flex-direction: column;
        padding: .2rem;
    }

    .main_1 {
        width: 100%;
        padding: 0px 2rem;
        align-items: center;
    }

    .long_text {
        font-size: 2rem;
        text-align: center;
    }

    .para {
        text-align: center;
    }

    .Lets_Talk {
        text-align: center;
    }

    .main_2 {
        display: none;
    }

    .main_3 {
        display: none;
    }
}
