.projectView {
  .wipClr {
    background: orange !important;
    color: #000 !important;
  }

  .completedClr {
    background: rgb(100, 185, 100) !important;
  }

  tr.wipClr > td {
    color: #000 !important;
  }

  tr.completedClr > td,
  tr.completedClr > td > .date-picker {
    color: #000 !important;
  }
}
