.create-project{
    

   .react-datepicker-wrapper, .datepicker-wrapper{
       width: 100%;
   }
   
   .datepicker-wrapper{
       margin-right:0;
   }

  
}