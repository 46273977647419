.divcont {}


.droper {
    color: rgb(71, 255, 77);
}

.floatingbutton {
    /* border: 10px solid red; */
    position: fixed !important;
    bottom: 20px;
    right: 20px;
    width: fit-content;
    z-index: 1000;
    cursor: pointer;
    height: fit-content;
}

.attachBox {
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;

}