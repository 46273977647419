.account {

    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 80vw;
    height: 90vh;
    margin: auto;
    height: auto;
    background: rgb(3 96 103 / 25%);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.Left_image {
    align-self: center;
    width: 45%;
}

.account__wrapper {
    align-self: center;
    width: 55%;
}

.cloud {
    width: 265px;
    height: 197px;
    background: rgb(0 109 117);
    /* z-index: 5; */
    box-shadow: 10px 10px rgb(2 102 110);
    border-radius: 100px;
    transition: rotate(180);
    position: absolute;
    opacity: 0.9;
    top: -9px;
    left: -78px;
}

.cloud::after,
.cloud::before {
    content: "";
    position: relative;
    display: inline-block;
    background: inherit;
    border-radius: inherit;
}

.cloud::after {
    width: 141px;
    height: 188px;
    top: -200px;
    left: 215px;
}

.cloud::before {
    width: 319px;
    height: 150px;
    top: -58px;
    left: 130px;
}

.cloud2 {
    width: 265px;
    height: 205px;
    background: rgb(0 109 117);
    /* z-index: 5; */
    box-shadow: 10px 10px rgb(2 102 110);
    border-radius: 100px;
    transition: rotate(180);
    position: absolute;
    opacity: 0.9;
    top: -9px;
    left: -78px;
    position: absolute;
    bottom: -18px;
    right: -79px;
    top: unset;
    left: unset;
    z-index: -1;
    transform: rotate(180deg);
}

.cloud2::after,
.cloud2::before {
    content: "";
    position: absolute;
    display: inline-block;
    background: inherit;
    border-radius: inherit;
}

.cloud2::after {
    width: 130px;
    height: 139px;
    top: 17px;
    left: 224px;
}

.cloud2::before {
    width: 335px;
    height: 84px;
    top: -1px;
    left: 118px;
}

@media screen and (max-width: 768px) {
    .Left_image {
        display: none;
    }

    .account__wrapper {
        align-self: center;
        width: 80%;
    }

}

@media screen and (max-width: 520px) {
    .Left_image {
        display: none;
    }

    .account__wrapper {
        align-self: center;
        width: 90%;
    }

    .account {
        width: 90vw;

    }
}

@media screen and (max-width: 425px) {
    .Left_image {
        display: none;
    }

    .account {
        width: 100vw;
    }

    .account__wrapper {
        align-self: center;
        width: 100%;
    }

    .cloud2 {
        right: -26px;
    }

    .account__title {
        margin-bottom: 60px;
        font-size: 2.4rem;
        word-spacing: 12px;
        letter-spacing: 4px;
        line-height: 54px;
    }

}