.table> :not(caption)>*>* {
  padding: 0rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);

  select {
    padding: 6px;
  }


}

.wipClr {
  background: orange !important;
  color: #000 !important;
}

.completedClr {
  background: rgb(100, 185, 100) !important;
}

tr.wipClr>td {
  color: #000 !important;
}

tr.completedClr>td,
tr.completedClr>td>.date-picker {
  color: #000 !important;
  ;
}


.wpForm {
  margin-top: 0 !important;
  margin-bottom: 0 !important;


  .regularWorkNpayments {
    background: #CCC;
    color: #000;
    font-weight: bolder;
    text-align: center;
  }

  .table .editable-table th,
  .table .editable-table td {
    min-width: 65px;
  }

  .table .editable-table th.datepicker,
  .table .editable-table th.datepicker {
    min-width: 150px;
  }

  // .table.table--not-pagination {
  //   max-height: 950px;
  // }

  td {
    vertical-align: middle;
  }

  .react-datepicker__input-container {
    position: static;
  }

  .table .editable-table th,
  .table .editable-table td {
    padding: 0 10px;
  }




}

@media (min-width: 576px) {
  .modal-dialog .customModalDialog {
    max-width: 60vw !important;
    margin: 1.75rem auto;
  }

}

.customModalDialog {
  max-width: 60vw !important;
}