.previewAttBox{
    padding: 20px;
    margin-top: 20px;
    /* border: 2px solid #c1bdbd85; */
    border-radius: 20px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}

.previewAtt{
    text-transform: capitalize;
    padding: 4px;
    font-weight: 400;
    display: inline-block;
    width: 106px;
}
.previewContent{
    /* text-transform: capitalize; */
    padding: 4px;
    font-weight: 400;
    display: inline-block;
    overflow-wrap:normal;
    width: 106px;
}
.AttDate{
    padding: 22px !important;
    border-radius: 4px !important;
}
.viewSelect{
    display: flex;
    flex-wrap: wrap;
    margin: 20px;
    place-content: space-around space-around;
    align-items: normal;
    height: 98px;
}
.viewSelect div{
    max-width:300px;
    justify-self:center;
}
.viewSelect > div{
    box-shadow: 6px 8px 12px rgba(142, 141, 141, 0.42);

}
.dateSelect p{
    position: relative;
    top: 8px;
    left: 14px;
    background-color: #ffff;
    z-index: 2;
    display: inline;
    padding: 4px;
    color: #0000007d;
}
.selectField{
    display: flex;
    align-content: space-around;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    margin: 10px;
}
.selectLab,.selectDate{
    min-width: 250px;
    background: #ececec;
    border-radius: 7px;
    box-shadow: 2px 2px 12px rgb(167, 167, 167);
    margin: 10px;
    padding: 2px;
}
.selectLab input{
    background: #ececec;
}
/* .TableElement[title]{
    content: attr(title);
    position: relative;
    top: 20px;
    right: 20px;
    font-weight: 500;
    color: #ffff;
    background: rgb(43, 42, 42);
    border-radius: 7px;
    box-shadow: 1px 2px 12px rgba(224, 224, 224, 0.724);
} */
.TableElement{
    font-weight: 600;
    font-size: 1rem;
    border-radius: 2px;
    padding-bottom: 2px;
    /* border:2px solid transparent; */
    /* border-right:#fff ; */
    margin: 5px;
    box-shadow: 2px 2px 3px 4px rgb(180 66 66), inset 2px 2px 3px 0px #fff;
}
.attProject{
    display: inline-block;
    position: relative;
    z-index: 3;
    top: 8px;
    left: 20px;
    background: #fff;
    font-weight: 400;
    font-size: 0.8rem;
    color: #a5a5a5;
}
.attProject2 > div{
    padding: 5px !important;
}
.error_project > div{
    border: 1px solid red;
    border-radius: 8px;
}
.tableLabourDashboard{
    /* width: 100vh; */
    padding:0px ;
}
.tableLabourDashboard > table{
    padding: 0px;
}


.dashboardFlex{
    display: flex;
    flex-direction: row;
}
.selectProject1{
    min-width: 193px;
    position: sticky;
    left: 0px;
    border: 0px;
}
.tableEle2 {
    width: 67px;
    text-shadow: 2px 2px 3px rgb(203, 201, 201);
}

.projectElement{
    font-weight: 500;
    position: sticky;
    left: 0px;
    background: #FFF;
    border: 0px;
    white-space: nowrap;
    top: 0px;
    z-index: 3,
}
/* tablate */
@media all and (min-width:768px) and (max-width:1024px) {

    .selectProject1 {
        min-width: 193px;
    }
    .tableEle2 {
        width: 55px;
    }
    .projectElement {
        display: table-cell;
        /* overflow-x: scroll; */
        width: auto;
    }

}

@media all and (min-width:480px) and (max-width:768px) {

    .selectProject1 {
        min-width: 193px;
    }
    .tableEle2 {
        width: 50px;
    }
    .projectElement {
        display: inline-block !important;
        overflow-x: hidden;
        text-overflow: ellipsis;
        width: 200px;
    }
}
input::placeholder{
    color: #727272 !important;
}

/* mobile */
@media all and (max-width:480px) {

    .selectProject1 {
        min-width: 83px;
        padding: 2px;
   }
    .tableEle2 {
        width: 18px;
        font-size: 0.7rem;
        font-weight: 900;
    }
    .projectElement {
        display: inline-block !important;
        overflow-x: scroll !important;
        text-overflow: clip !important;
        width: 90px !important;
        margin-right: 16px !important;
        font-size: .7rem !important;
        font-weight: 800 !important;
        
    }
    
    
}