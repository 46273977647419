.colFlex {
    flex: 1 0 53vw;
}

/* tablate */
@media all and (min-width:768px) and (max-width:1024px) {

    .colFlex {
        flex: 1 0 40vw;
    }

}

@media all and (min-width:480px) and (max-width:768px) {

    .colFlex {
        flex: 1 0 23vw;
    }

}

/* mobile */
@media all and (max-width:480px) {

    .colFlex {
        flex: 1 0;
    }
}